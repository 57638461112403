@keyframes loading {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.loading-dots {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;

  &__circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: loading 1s cubic-bezier(0.1, 0.3, 0.2, 1.4) infinite;
    transform-origin: bottom center;
    position: relative;
    transform: translateZ(0);

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  &--circle-1 {
    animation-delay: 0.1s;
    background-color: #41d6c3;
  }

  &--circle-2 {
    animation-delay: 0.2s;
    background-color: #42a1ec;
  }

  &--circle-3 {
    animation-delay: 0.3s;
    background-color: #ffcc00;
  }
}

.loading-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  &__message {
    &-text {
      margin-top: 30px;
      text-align: center;
    }
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.spin--empty {
    position: relative;
  }

  &__item {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: text-bottom;
    border: 0.25em solid #41d6c3;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
  }
}
