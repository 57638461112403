.badge {
  display: inline-block;
  padding: 3px 15px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;

  &--warning {
    background: var(--color-warning);
    color: var(--color-darkGrey);
  }

  &--info {
    background: var(--color-lightBlue);
    color: var(--color-darkGrey);
  }

  &--success {
    background: var(--color-lightGreen);
    color: var(--color-darkGrey);
  }

  &--danger {
    background: var(--color-danger);
    color: var(--color-darkGrey);
  }

  &--grey {
    background: var(--color-lightGrey);
    color: var(--color-darkGrey);
  }
}
