.chatbox-header {
  display: flex;

  &.hide {
    display: none !important;
  }

  &:not(.chatbox-header--sidebar) {
    flex-grow: 1;
  }

  &-group {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: 60px;

    @media (max-width: 767px) {
      &.chat-active {
        .chatbox-header:not(.chatbox-header--sidebar) {
          display: none;
        }
      }
    }

    &.chat-active {
      .chatbox-header {
        > .container {
          max-width: 100%;
        }
      }
    }
  }

  &-menu {
    display: flex;
    flex-wrap: nowrap;
    flex: 1 1 auto;
    margin-left: -15px;
    margin-right: -15px;
    min-height: 100%;

    &__back-button {
      display: flex;
      width: 30%;
      flex-shrink: 0;

      @media (min-width: 768px) {
        width: 20%;
      }
    }

    &__end-consultation-button {
      display: flex;
      flex-shrink: 0;

      @media (min-width: 768px) {
        width: 20%;
      }
    }

    &__info {
      display: flex;
      flex: 0 1 auto;
      padding: 10px;
      min-width: 0;
      width: 40%;

      @media (min-width: 768px) {
        width: 60%;
        margin-left: auto;
      }

      &-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        min-width: 0;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;

        @media (min-width: 768px) {
          font-size: 14px;
        }

        &-chat {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1;
          min-width: 0;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 18px;
        }
      }
    }

    &__button {
      padding: 15px 15px;
      flex-shrink: 0;
      min-width: 40px;
      justify-content: center;

      &-group {
        display: flex;
        flex: 0 auto;
        padding: 10px 5px;
        justify-content: flex-end;

        width: 30%;

        .unread-chat {
          &:before {
            content: '';
            display: block;
            height: 8px;
            width: 8px;
            background-color: var(--color-error);
            border-radius: 100%;
            position: absolute;
            top: 6px;
            right: 10px;
            border: 2px solid #ffffff;
          }
        }

        .button {
          padding: 10px 15px;
          align-items: center;
          position: relative;
        }

        @media (min-width: 768px) {
          width: 20%;
          padding: 10px;
        }
      }
    }

    &__mobile {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      flex: 1 1 auto;
      padding: 0px 5px;
      min-width: 0;

      &-info {

        flex: 0 1 auto;
        padding: a;
        width: 40%;
        text-align: center;
        font-size: 18px;
      }

      &-close{
        display: flex;
        flex-shrink: 0;
        width:30%;

        .button{
          padding: 10px 15px;
          align-items: center;
          position: relative;
        }
      }

      &-button {
        flex: 0 auto;
        padding: 10px 0px;
        width: 30%;
        display: flex;
        justify-content: flex-end;

        .button{
          padding: 10px 15px;
          align-items: center;
          position: relative;
        }
      }

      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  &--sidebar {
    border-left: 1px solid #eef1f5;
    width: 100%;

    .chatbox-header-menu__info {
      display: none;
      flex: 1 1 auto;
    }

    @media (min-width: 768px) {
      width: 350px;

      .chatbox-header-menu__info {
        display: flex;
      }
    }
  }
}

.video-call {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  flex: 1 1 auto;
  background: #000000;
  z-index: 1001;
  min-height: 0;
  transition: 0.3s all ease;
  display: none;

  &__user {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    z-index: 1000;
    min-height: 0;

    &--minimize {
      position: absolute;
      top: calc(55px + 15px);
      left: 15px;
      height: 140px;
      width: 110px;
      z-index: 1001;

      @media (min-width: 768px) {
        right: 30px;
        left: auto;
      }
    }
  }

  &__navbar {
    display: flex;
    flex: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    background: rgba(64, 72, 82, 0.6);
    height: 55px;

    &-button {
      padding: 15px;
      flex-shrink: 0;
      display: flex;
    }

    &-info {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      color: #ffffff;
      padding: 10px;
      min-width: 0;

      @media (min-width: 768px) {
        justify-content: center;
      }
    }

    .minimize-desktop {
      display: none;
      margin: 10px;
    }

    @media (min-width: 768px) {
      height: 75px;

      .minimize-mobile,
      .switch-camera {
        display: none;
      }

      .minimize-desktop {
        display: flex;
      }
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    flex: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1001;
    padding: 30px;
    transition: 0.3s all ease;

    button {
      padding: 0;

      &:hover {
        opacity: 0.8;
      }

      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }

  &.active {
    display: flex;
  }

  &.split {
    flex: 0 1 50%;
    position: relative;

    & ~ .chat {
      flex: 0 1 50%;
    }
  }

  @media (min-width: 768px) {
    &:not(.active) {
      top: 100px;
      right: 30px;
      left: auto;
      display: flex;
      width: 400px;
      height: 250px;
    }

    &:hover {
      .video-call__action {
        opacity: 1;
      }
    }

    .video-call__action {
      opacity: 0;
    }
  }
}

.video-call-alert {
  background: #4cda63;
  cursor: pointer;
  &__info {
    display: flex;
    flex: 1;
    padding-top: 10px;
    padding-bottom: 10px;

    &-text {
      flex-grow: 1;
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
}

.browser-notice {
  &__header {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  &__message {
    margin-top: 15px;
    margin-bottom: 15px;

    p {
      line-height: 20px;
      margin-bottom: 10px;
    }

    &--windows {
      &.card {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        align-items: center;

        .card-body {
          width: 700px;
          max-width: 100%;
        }
      }
    }
  }
  &__action {
    margin-top: 15px;
  }

  &__row {
    display: flex;
    flex: 1 1 auto;
    margin-right: -15px;
    margin-left: -15px;
  }

  &__col {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (max-width: 767px) {
    .card {
      border-radius: 0px;
      margin-right: -15px;
      margin-left: -15px;
    }
  }
}
