.toolbox {
  display: flex;
  justify-content: center;
  margin: 24px 0 16px 0;
  width: 100%;
  z-index: 300;

  &-content {
    background: #131519;
    border-radius: 6px;
    margin: 0 auto;
    padding: 6px;
    display: flex;
    pointer-events: all;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.25), 0 0 0 1px rgba(0, 0, 0, 0.15);
    gap: 6px;
  }
  &-button {
    &-container {
      position: relative;
    }

    color: #fff;
    cursor: pointer;
    display: inline-block;
    line-height: 48px;
    text-align: center;
    border-radius: 3px;
    .settings-button-small-icon {
      background: #36383c;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 0 1px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      cursor: pointer;
      padding: 4px;
      position: absolute;
      right: -4px;
      top: -3px;
      svg {
        fill: #ffffff;
        display: block;
      }
      &:hover {
        background: #f2f3f4;
        svg {
          fill: #000000;
        }
      }
    }

    &-hangup {
      background-color: #dd3849;
    }
  }
  &-icon {
    position: relative;
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 3px;
    font-size: 24px;
    height: 48px;
    width: 48px;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
    svg {
      fill: #fff;
    }
    &.toggled {
      background: rgba(255, 255, 255, 0.15);
    }
    & > .screen-share-icon {
      filter: invert(1);
    }
  }
  &-overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    transition: bottom 0.3s ease-in;
    width: 100%;
  }
  &-source-list {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 0;
    background: #242528;
    border-radius: 3px;
    box-sizing: content-box;
    color: #ffffff;
    max-height: 448px;
    max-width: 448px;
    padding: 16px 0;
    z-index: 300;
    box-shadow: rgba(13, 20, 36, 0.85) 0px 4px 8px -2px, rgba(13, 20, 36, 0.81) 0px 0px 1px;
    display: flex;
    flex-direction: column;
    .source {
      &-entry {
        position: relative;
        display: flex;
        align-items: center;
        &:hover {
          background: #36383c;
        }
        &--selected {
          margin-left: 0;
          padding-left: 18px;
          background: #36383c;
          .source-entry-label {
            margin-left: 0;
          }
        }
      }
      &-entry-icon {
        margin-right: 16px;
      }
      &-entry-label {
        align-items: center;
        color: #fff;
        cursor: pointer;
        display: flex;
        padding: 8px 16px 8px 0;
        margin-left: 48px;
        max-width: 175px;
        span {
          font-size: 14px;
          display: inline-block;
          line-height: 24px;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
