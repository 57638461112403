.sidebar {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  background-color: #ffffff;
  border-left: 1px solid var(--color-border);
  overflow-x: hidden;
  height: calc(100vh - 60px);
  position: relative;

  &__info-title {
    font-size: 18px;
    font-weight: 700;
    color: #404852;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  &.hide {
    display: none;
  }
}

.btn-manage {
  background: transparent;
  color: #31a598;
  padding: 15px;
  font-weight: 600;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  border: 1px solid #31a598;
  font-size: 16px;

  &:hover {
    color: #ffffff;
    background: #31a598;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-left: 0;
  margin-bottom: 0;

  &__item {
    position: relative;
    display: block;
    padding: 20px 15px;
    text-decoration: none;
    color: #404852;
    cursor: pointer;
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);

    & + & {
      border-top-width: 0;
    }
  }

  &__title {
    color: #404852;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
  }
}

.doctor-detail {
  list-style: none;
  margin: 0;
  padding: 0;

  &__subtitle {
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
    display: block;
    color: #a1a5aa;

    &--value {
      color: #404852;
      font-size: 14px;
      line-height: normal;
    }
  }
}
