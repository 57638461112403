.button {
  padding: 10px;
  transition: 0.3s all ease;
  display: flex;
  flex-shrink: 0;
  border-radius: 5px;
  &:disabled {
    filter: grayscale(1) brightness(100%);
    pointer-events: none;
  }

  &-big {
    padding: 12px 16px;
    font-size: 16px;
  }

  &.clear {
    &:hover {
      background: #f5f5f5;
    }

    &-blue:hover,
    &-blue.active {
      background: #ecf7fe;
    }
  }

  &.green {
    background: #31a598;
    color: #ffffff;

    &:hover {
      background: #136c62;
    }

    &.outline {
      background: #ffffff;
      border: 1px solid #31a598;
      color: #31a598;

      &:hover {
        background: #136c62;
        border: 1px solid #136c62;
        color: #ffffff;
      }
    }
  }

  &.red {
    background: #cc1414;
    color: #ffffff;

    &:hover {
      background: darken(#cc1414, 10%);
    }
  }
}
