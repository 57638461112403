.dropdown {
  display: flex;
  position: relative;

  &-menu {
    transition: 0.3s all ease;
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    opacity: 0;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    min-width: 150px;
    -webkit-animation-name: fadeIn; /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.3s; /* Safari 4.0 - 8.0 */
    animation-name: fadeIn;
    animation-duration: 0.3s;

    &__item {
      text-align: left;
      display: flex;
      flex: 1 1 auto;

      button {
        text-align: left;
        display: flex;
        flex: 1 1 auto;
      }
    }
  }

  &--open {
    .dropdown-menu {
      opacity: 1;
    }
  }
}
