// for standard html style
// @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
//     html {
//       transform: rotate(-90deg);
//       transform-origin: left top;
//       width: 100vh;
//       overflow-x: hidden;
//       position: absolute;
//       top: 100%;
//       left: 0;
//     }
//   }

%base {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --color-primary: #007acc;
  --color-secondary: #ebf5fd;
  --color-lightBlue: #cce4f5;
  --color-lightGrey: #ecedee;
  --color-grey: #797f86;
  --color-darkGrey: #404852;
  --color-blueLight: #ecf7fe;
  --color-lightYellow: #fef8de;
  --color-error: #d43939;
  --color-danger: #f5d0d0;
  --color-success: #41d6c3;
  --color-warning: #fff5cc;
  --color-lightGreen: #d6edea;
  --color-border: #ebeef4;
  --grid-maxWidth: 1140px;
  --grid-gutter: 30px;
  --font-size: 14px;
  --font-family: 'Nunito Sans', sans-serif;
}

* {
  font-family: 'Nunito Sans', sans-serif;
}

html,
body,
#root {
  @extend %base;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 100%;
  height: 100%;
  font-size: 14px;
}

body {
  background: #ffffff;
}

a {
  text-decoration: none;
}

code {
  font-family: 'Nunito Sans', sans-serif;
}

ul {
  li {
  }
}

input {
  font-size: 14px;
}

::placeholder {
  color: #c5c8cb;
}

textarea {
  font-size: 14px;
}

ol {
  li {
  }
}

button {
  background-color: transparent;
  border: 0px;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  transition: 0.3s all ease;
}

input,
textarea {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h6 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}

p {
  line-height: 1.2;
  margin: 0;
}

small {
  font-size: 12px;
}
