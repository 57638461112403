.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1 1 auto;
  flex-direction: column;

  &-dialog {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: auto;
    pointer-events: none;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    background: rgba(48, 48, 48, 0.9);
    padding: 15px;
  }

  &-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    outline: 0;
    transform: translateY(-50px);
    max-width: 100%;
    background: #ffffff;
    border-radius: 5px;
  }

  &-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 15px;
  }

  &-close {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1050;

    &__icon {
      width: 20px;
    }

    &__button:hover {
      opacity: 0.6;
    }
  }

  &-full {
    .modal-dialog {
      padding: 0;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      transform: none;
      border-radius: 0;
    }
  }
}

// Modal Styling

.confirmation-modal {
  text-align: center;

  .modal-body {
    padding: 20px;
  }

  h4 {
    margin-bottom: 10px;
  }

  &__action {
    display: flex;
    flex-wrap: nowrap;
    flex: 1 1 auto;
    border-top: 1px solid #f5f5f5;
    overflow: hidden;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__button {
    flex: 1;
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      border-right: 1px solid #f5f5f5;
    }
  }
}

.modal-thank-you {
  text-align: center;

  &__action {
    display: flex;
    flex-direction: column;
  }

  &__button {
    justify-content: center;
  }
}

.image-modal {
  .modal-content {
    background: #000000;
    min-height: 400px;
    max-width: 100%;
    max-height: 90%;
    width: auto;

    @media (min-width: 768px) {
      min-width: 400px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    max-width: 100%;
    max-height: 100%;

    .img-attach-preview {
      width: auto;
      height: auto;
      max-width: 50%;
      max-height: 85%;

      @media (max-width: 768px) {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
