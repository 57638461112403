textarea.chat__input-box {
  font-size: 16px;

  @media(min-width: 768px) {
    font-size: 14px;
  }
}

.chat {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 0;
  color: #363d45;
  border-top: 1px solid #e8e9ea;

  &__log {
    display: flex;
    flex-grow: 1;
    overflow: auto;
    min-height: 0;
    background: #ffffff;

    .container {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      min-width: 0;
    }

    &--not-started {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &-notice {
      text-align: center;
      width: 350px;
      max-width: 100%;

      *:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__input {
    --grid-gutter: 16px;

    flex-grow: 0;
    padding: 12px 0px;
    border-top: 1px solid #e8e9ea;

    &-iPhoneOS15Safari {
      padding-bottom: calc(65px + env(safe-area-inset-bottom, 10px));
    }

    &-rate {
      padding: 10px;
      display: flex;
      flex: 1;
      flex-direction: column;

      .button {
        justify-content: center;
      }
    }

    &-box {
      border: none;
      padding: 14px 12px;
      color: #363d45;
      outline: none;
      flex-grow: 1;
      border-radius: 5px;
      line-height: 21px;
      resize: none;
      background: #f6f7fa;

      &-wrapper {
        display: flex;
        flex-grow: 1;
        padding-left: 8px;
        padding-right: 8px;
        min-width: 0;
      }
    }

    &-actions {
      display: flex;

      &.is-typing .chat__input-attachment {
        @media (max-width: 767px) {
          display: none;
        }
      }
    }

    &-send {
      display: none;
      flex-shrink: 0;
      align-items: center;
      padding: 5px 8px;
      margin: 0px 8px 0px 8px;
      border-radius: 5px;

      @media (min-width: 768px) {
        display: flex;
      }

      &--active {
        display: flex;
        background: var(--color-primary);

        img {
          filter: invert(1) brightness(100);
        }

        &:focus,
        &:hover {
          opacity: 0.6;
        }
      }
    }

    &-upload {
      display: flex;
      align-items: center;
      padding: 0 8px;
    }

    &-attachment {
      display: flex;
      align-items: center;
      padding: 0 8px;
      transition: 0.3s all ease;

      &-button {
        display: flex;
        flex-shrink: 0;

        .button {
          align-items: center;
        }

        p {
          display: none;
          margin-left: 5px;

          @media (min-width: 768px) {
            display: flex;
          }
        }

        .row-filename,
        .row-filename-empty {
          display: none;
        }
      }

      &:hover,
      &:focus {
        opacity: 0.6;
      }
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
      select,
      textarea,
      input {
        font-size: 16px;
        background: #f6f7fa;
      }
    }
  }

  &--readonly {
    height: 100vh;
  }

  &--sidebar {
    width: 100%;
    height: calc(100vh - 60px);
    border-left: 1px solid var(--color-border);

    @media (min-width: 768px) {
      width: 350px;
    }

    .ended-text {
      font-size: 12px;

      @media (min-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

.chat-attachment {
  display: flex;
  flex: 1 1 auto;
  background: #f5f5f5;
  margin: 0;
  min-width: 0;
  padding: 10px;
  border-radius: 5px;

  &:not(:last-child) {
    margin: 10px 10px 0px 10px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &__preview {
    background: #ffffff;
    border-radius: 5px;
    flex-grow: 1;
    position: relative;
    min-width: 0;

    &-info {
      display: flex;
      flex: 1;
      padding: 10px;
      align-items: center;
      min-width: 0;
    }

    &-clear {
      position: absolute;
      top: 0;
      right: 0;
    }

    .thumbnail {
      width: 50px;
      height: 50px;
      margin-right: 10px;

      @media (min-width: 768px) {
        width: 80px;
        height: 80px;
      }
    }
  }

  & ~ .chat__input-box {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.chat-message {
  align-items: center;
  display: flex;
  padding-bottom: 8px;
  padding-top: 8px;
  justify-content: flex-start;

  &__profile-picture {
    margin-right: 12px;

    .thumbnail {
      width: 32px;
      height: 32px;

      &-default {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        background: #f6f7fa;
      }
    }

    &--right {
      margin-right: 0;
      margin-left: 12px;
    }
  }

  &__image {
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    .thumbnail {
      flex: 1;
      width: auto;
      height: 150px;
      &--square {
        width: 150px;
      }
    }

    .blur {
      img {
        filter: blur(20px);
        -webkit-filter: blur(20px);
      }
    }

    .retry-button {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -30px;
      margin-top: -10px;
      width: 60px;
      justify-content: center;
      font-weight: bold;
    }
  }

  &__attachment {
    margin-bottom: 10px;
    display: flex;
    background: #ffffff;
    padding: 10px;
    border-radius: 5px;
    align-items: center;
    min-width: 0;

    .thumbnail {
      margin-right: 10px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 8px;
    background-color: #f6f7fa;
    border-radius: 10px;
    max-width: 90%;
    word-break: break-all;

    &-doc-system-name {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;

      & > img {
        margin-right: 10px;
      }
    }

    &-text {
      font-size: 14px;
      line-height: 1.43;
    }
  }

  &__content-copy {
    align-items: center;
    display: flex;
    cursor: pointer;
    padding-left: 8px;
    padding-right: 8px;
  }

  &__content-time {
    margin-top: 5px;
    text-align: right;
    color: #6b7178;
    font-size: 11px;
  }

  &__content-info {
    margin-top: 5px;
    text-align: center;
    color: #767b82;
  }

  &__content-call {
    display: flex;
    flex-wrap: nowrap;

    &--icon {
      padding-right: 10px;
    }
  }

  &.current-user {
    justify-content: flex-end;

    .chat-message__content {
      color: #ffffff;
      background-color: var(--color-primary);

      &-time {
        color: #fff;
      }
    }
  }
}

.chat-nav {
  display: flex;
  flex: 0 1 auto;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eaeaea;

  &__row {
    display: flex;
    flex: 1 1 auto;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__col {
    padding-left: 15px;
    padding-right: 15px;
    flex-grow: 1;
    display: flex;
  }
}

.alert {
  background: var(--color-lightGreen);
  color: var(--color-success);
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  display: inline-block;
  margin: 10px auto;

  &:not(.alert--full) {
    min-width: 70%;

    @media (min-width: 768px) {
      min-width: 30%;
    }
  }

  &--full {
    display: inline-flex;
    margin: 0;
    justify-content: center;
  }

  &--warning {
    background: var(--color-warning);
    color: #404852;
  }

  &--incoming {
    color: #404852;
  }

  &--button {
    color: #c7e7ff;
  }

  &--transparent {
    padding: 12px 0;
    margin: 0;
    background: transparent;
    color: #6b7178;
    font-size: 12px;
  }
}
