.video-embed {
  display: none;
  flex: 1 1 auto;
  min-height: 0;
  min-width: 0;

  &.active {
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(100vh - 60px);
  }

  &.video-joined {
    width: min-content;
  }

  .video-pre-meeting {
    width: 100%;
    background-color: black;
    object-fit: cover;
    height: 344px;
    @media (max-width: 375px) and (max-height: 667px) {
      height: 270px;
    }
    @media (min-width: 1024px) {
      height: 270px;
    }
    @media (min-width: 1441px) {
      height: 344px;
    }
  }
}
